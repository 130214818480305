import {inject} from 'aurelia-framework';
import {JwtService} from '../../shared/services/jwtservice';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {SalaryRevisionService} from '../../shared/services/salaryrevisionservice';
import flatpickr from "flatpickr";
import {constants} from '../../shared/services/constants';
import {config} from '../../shared/services/config';
import * as XLSX from 'xlsx';
import {LeavemanagementService} from "../../shared/services/leavemanagementservice";
import { PromotionLetter } from './employeesalary/employeesalarycomponent';
import { Router } from 'aurelia-router';


@inject(DialogController, JwtService, DialogService, SalaryRevisionService, LeavemanagementService,Router)
export class SalaryRevision {
    EmployeeSalaryRevisionList = [];
    FromDate = '';
    ToDate = '';
    isDropdownOpen = false;
    selectedStatus = 'ACT';
    defaultToDate;
    defaultFromDate;
    showEmployeeDropdown = false;
    FavoritesExist = false;
    sitemapId = constants.employeesalaryrevisionlistsitemapid;
    pageSize = config.grid_PazeSize;
    statusOptions = [
        {label: 'All', value: 'All', checked: false},
        {label: 'Pending submission', value: 'ACT', checked: true},
        {label: 'Pending approval', value: 'PEA', checked: true},
        {label: 'Need clarification', value: 'NED', checked: false},
        {label: 'Approved', value: 'APP', checked: false}
    ];

    constructor(controller, JwtService, DialogService, SalaryRevisionService, LeavemanagementService,router) {

        this.controller = controller;
        this.jwtService = JwtService;
        this.empid = this.jwtService.getEmployeeId();
        this.salaryrevisionService = SalaryRevisionService;
        this.leavemanagementService = LeavemanagementService;
        this.dialogService = DialogService;
        this.router = router;

    }

    attached() {
      
        
        this.LoginempId = window.localStorage['EmployeeID'];
        const fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 1);
        this.FDate = fromDate.toLocaleDateString().replace(/\//g, '-');
        this.From = this.FDate;
        const toDate = new Date();
        toDate.setMonth(toDate.getMonth() + 1);
        this.TDate = toDate.toLocaleDateString().replace(/\//g, '-');
        this.To = this.TDate;
        flatpickr(this.FromdatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: fromDate,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens: false,
            onClose: function (selectedDates, dateStr, instance) {
                this.FromDate = dateStr;
            }
        });
        this.FromcalendarIcon.addEventListener('click', () => {
            this.FromdatePicker._flatpickr.open();
        });
        flatpickr(this.TodatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: toDate,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens: false,
            onClose: function (selectedDates, dateStr, instance) {
                this.default = dateStr;
            }
        });
        this.TocalendarIcon.addEventListener('click', () => {
            this.TodatePicker._flatpickr.open();
        });
        this.leavemanagementService.GetPersonFavoriteItems(this.empid, this.sitemapId)
        .then(data => {
            this.FavoritesExist = data;
        });
    this.addToRecentItems();
    this.LoginempId = window.localStorage['EmployeeID'];
        this.salaryrevisionService.DepartmentDropdown(this.LoginempId, 'ACT')
            .then(data => {
                this.departments = data;
            });
        const selectedStatusList = this.statusOptions
            .filter(status => status.checked)
            .map(status => status.value);
        const listData = {
            FromDateText: this.From,
            ToDateText: this.To,
            Status: selectedStatusList.join(","),
            EmployeeID: this.empid,
        }
        this.isLoading = true;
        this.salaryrevisionService.getEmployeeSalaryRevision(this.FromDateText,this.ToDateText,this.Status,this.EmployeeID)
                .then(data =>{
                this.EmployeeSalaryRevisionList = data; 
                console.log(data);        
                });

        this.salaryrevisionService.PostEmployeeSalaryRevision(listData)
            .then(data => {
                this.EmployeeSalaryRevisionList = data;
                this.isLoading = false;
            });

        this.salaryrevisionService.GetPersonFavoriteItems(this.LoginempId, this.sitemapId)
            .then(data => {
                this.FavoritesExist = data;
            });

           
        this.addToRecentItems();
    }

    filters = [
        {value: '', keys: ['EmployeeName', 'PositionlevelName', 'RevisionPeriod', 'NextRevisionDueDate']},
    ];

    addToFavourites() {
        this.salaryrevisionService.AddToFavourites(this.LoginempId, this.sitemapId).then(data => {
            this.FavoritesExist = data;
            window.location.reload();

        });
    }

    filterClick() {
        event.preventDefault();
        const clickedDiv = event.target.parentNode;
        clickedDiv.classList.toggle('filter-open');
        const divs = document.querySelectorAll('.filter-open');
        divs.forEach((div) => {
            if (div !== clickedDiv) {
                div.classList.remove('filter-open');
            }
        });
        const handleOutsideClick = (event) => {
            if (!clickedDiv.contains(event.target)) {
                divs.forEach((div) => {
                    div.classList.remove('filter-open');
                });
                document.removeEventListener('click', handleOutsideClick);
            }
        };
        if (clickedDiv.classList.contains('filter-open')) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }
    }

    async displayEmployeess(event) {
        this.showEmployeeDropdown = true;
        const statusVal = event.target.value;
        this.salaryrevisionService.DepartmentDropdown(this.LoginempId, statusVal)
            .then(data => {
                this.departments = data;
            });

    }

    selectEmployees(empOptions) {
        const modifiedOption = {...empOptions, ID: empOptions.EmployeeID};
        this.eName = modifiedOption.EmployeeName;
        this.eNameID = modifiedOption.EmployeeID;
        this.showEmployeeDropdown = false;
        this.selectedEmployee = modifiedOption;
        const finalToDate = this.ToDate ? this.ToDate : this.defaultToDate;
        const finalFromDate = this.FromDate ? this.FromDate : this.defaultFromDate;
        const timeoff = {
            Fromdate: finalFromDate,
            Todate: finalToDate,
            EmployeeId: this.eNameID,
        };
    }

    ApproveShowDialog(SalaryRevisionID, EmployeeID) {
        const salaryData = {
            SalaryRevisionID: SalaryRevisionID,
            selectedEmployeeID: EmployeeID,
        };
        const queryString = new URLSearchParams(salaryData).toString();
        const salaryURL = `/#/employeesalary?${queryString}`;
        window.location.href = salaryURL;
    }

    Search() {
        const filterOpenElements = document.querySelectorAll('.filter-open');
        filterOpenElements.forEach(element => element.classList.remove('filter-open'));
        const selectedStatusLists = this.selectedStatuses.map(status => status.value);
        const selectedStatusList = this.statusOptions
            .filter(status => status.checked)
            .map(status => status.value);

        const finalToDate = this.ToDate ? this.ToDate : this.defaultToDate;
        const finalFromDate = this.FromDate ? this.FromDate : this.defaultFromDate;
        const selectedRadio = document.querySelector('input[name="employees"]:checked');
        this.selectedValue = selectedRadio ? selectedRadio.value : '';
        const listData = {
            FromDateText: finalFromDate,
            ToDateText: finalToDate,
            EmployeeStatus: this.selectedValue,
            Status: selectedStatusList.includes('All') ? ['All'] : selectedStatusList.filter(status => status !== 'All').join(","),
            DepartmentID: this.department,
            EmployeeID: this.empid,
            LoggedInEmployeeId: this.LoginempId,
        };
        //this.isLoading = true;

        this.salaryrevisionService.PostEmployeeSalaryRevision(listData)
            .then(data => {
                this.EmployeeSalaryRevisionList = data;

            });
    }

    selectAllChanged(event) {
        const isChecked = this.statusOptions[0].checked;
        this.statusOptions.forEach((status, index) => {
            if (index !== 0) {
                status.checked = isChecked;
            }
        });
    }

    handleCheckboxChange(event, status) {
        if (status.value === 'All') {
            this.statusOptions.forEach((statusOption, index) => {
                if (index !== 0) {
                    statusOption.checked = false;
                }
            });
        } else {
            const allChecked = this.statusOptions.slice(1).every(statusOption => statusOption.checked);
            this.statusOptions[0].checked = allChecked;
        }

        //status.checked = event.target.checked;
    }

    resetDepartmentDropdown() {
        this.department = ''; // Reset the selected department to empty value
    }

    resetStatusDropdown() {
        this.statusOptions.forEach((status, index) => {
            if (index === 1 || index === 2) {
                status.checked = true; // Select 'Pending approval' and 'Pending with me'
            } else {
                status.checked = false; // Deselect other statuses
            }
        });
        // Update the selectedStatusList if needed
        this.selectedStatusList = this.statusOptions.filter(status => status.checked);
    }

    exportData() {
        const data = this.EmployeeSalaryRevisionList;
        const filename = 'EmployeeSalaryRevision';
        this.exportToExcel(data, filename);
    }

    exportToExcel(data, filename) {
        const headers = {
            Employeename: 'Employee name',
            Department: 'Department',
            Position: 'Position',
            Revisionperiod: 'Revision period',
            Revisionduedate: 'Revision due date',
            Status: 'Status',
        };
        const filteredData = data.map(revision => {
            return {
                Employeename: revision.EmployeeName,
                Department: revision.DepartmentName,
                Position: revision.PositionlevelName,
                Revisionperiod: revision.RevisionPeriod,
                Revisionduedate: revision.NextRevisionDueDate,
                Status: revision.StatusCode,

            };
        });
        const headerRow = Object.values(headers);
        const dataRows = filteredData.map(leave => Object.values(leave));
        const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...dataRows]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    // addToFavourites() {
    //     this.leavemanagementService.AddToFavourites(this.empid, this.sitemapId).then(data => {
    //         window.location.reload();
    //     });
    // }

    addToRecentItems() {
        this.leavemanagementService.AddToRecentItems(this.empid, this.sitemapId).then(data => {

        });
    }

    resetPage() {
        this.attached();
        this.selectedStatus = 'ACT';
    }

    Reset() {
        this.attached();
    }

    get selectedStatuses() {

        return this.statusOptions
            .filter(status => status.checked);

    }

    removeFilter(Allstatus, status) {
        if (status.label === 'All') {
            this.statusOptions.forEach(option => {
                option.checked = false;
            });
        } else {
            status.checked = false;
        }
    }

    // showDialog(){
    //     this.dialogService.open({viewModel: PromotionLetter, model: ""});
    // }

    ViewSalaryRevision(){
        this.router.navigate('employeesalary');
    }

    ViewEmployeeSalary(ActionName,SalaryRevisionID) {
        const approveData = {
            ActionName: ActionName,
            SalaryRevisionID:SalaryRevisionID
        };
        const queryString = new URLSearchParams(approveData).toString();
        const performancereviewURL = `/#/employeesalary?${queryString}`;
        window.location.href = performancereviewURL;
    }
}
