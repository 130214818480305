import {inject} from 'aurelia-framework';
import {JwtService} from '../../../shared/services/jwtservice';
import {DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';

@inject(DialogController,JwtService,DialogService)
export class PromotionLetter {
   
    constructor(controller,JwtService,DialogService){
        this.controller = controller;
        this.jwtService= JwtService;
        this.dialogService = DialogService;
    }

}
