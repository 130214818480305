import {inject} from 'aurelia-framework';
import {JwtService} from '../../../shared/services/jwtservice';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {config} from "../../../shared/services/config";
import {SalaryRevisionService} from '../../../shared/services/salaryrevisionservice';

import flatpickr from "flatpickr";

@inject(DialogController, JwtService, DialogService, SalaryRevisionService)
export class QuestionnairePopup {
    isLoading = false;
    static inject = [DialogController, Element];
    isDragging = false;
    dialog;
    pageSize = config.kra_PazeSize;

    constructor(controller, JwtService, DialogService, SalaryRevisionService) {
        this.controller = controller;
        this.jwtService = JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.salaryRevisionService = SalaryRevisionService

    }

    activate(data) {
        this.params = data;
        this.EmployeeID = this.params.EmployeeID;
        this.SalRevisionID = this.params.SalRevisionID;


    }

    attached() {
        this.salaryRevisionService.Questionlist(this.SalRevisionID, this.EmployeeID).then(data => {
            this.question = data;
        })
    }

    openFileDialog() {
        document.getElementById('attachments').click();
    }

    handleFileSelect(files) {
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024;
        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 4000);
            this.removeFile();
            return;
        }
        else if (file.size <= maxSize) {
            this.files = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentNames = file.name;
            reader.onload = () => {
                this.files = reader.result;
            };
        }
    }

    removeFile() {
        this.files = null;
        this.DocumentNames = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }


}
