import {inject} from 'aurelia-framework';
import {JwtService} from '../../../shared/services/jwtservice';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {config} from "../../../shared/services/config";
import {SalaryRevisionService} from '../../../shared/services/salaryrevisionservice';

import flatpickr from "flatpickr";

@inject(DialogController, JwtService, DialogService, SalaryRevisionService)
export class OtherEmployeesInSamePosition {
    isLoading = false;
    static inject = [DialogController, Element];
    isDragging = false;
    dialog;
    pageSize = config.kra_PazeSize;

    constructor(controller, JwtService, DialogService, SalaryRevisionService) {
        this.controller = controller;
        this.jwtService = JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.salaryRevisionService = SalaryRevisionService

    }

    activate(data) {
        console.log(data);
        this.params = data;
        this.LevelName = this.params.LevelName;
        this.PersonId = this.params.PersonId;
        this.Position = this.params.Position;
        this.DepartmentName=this.params.DepartmentName;
          }

    attached() {
        this.salaryRevisionService.GetOtherPositionDetails(this.Position, this.LevelName,this.DepartmentName,this.PersonId, this.empid).then(data => {
            this.question = data;
        })
    }



}
