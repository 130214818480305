import {inject} from 'aurelia-framework';
import {JwtService} from '../../shared/services/jwtservice';
import {DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';

@inject(DialogController,JwtService,DialogService)
export class Resignation {
   
    constructor(controller,JwtService,DialogService){
        this.controller = controller;
        this.jwtService= JwtService;
        this.dialogService = DialogService;
    }

    filterClick() {
        event.preventDefault();
        const clickedDiv = event.target.parentNode.parentNode;
        clickedDiv.classList.toggle('filter-open');
        const divs = document.querySelectorAll('.filter-open');
        divs.forEach((div) => {
            if (div !== clickedDiv) {
                div.classList.remove('filter-open');
            }
        });
        const handleOutsideClick = (event) => {
            if (!clickedDiv.contains(event.target)) {
                divs.forEach((div) => {
                    div.classList.remove('filter-open');
                });
                document.removeEventListener('click', handleOutsideClick);
            }
        };
        if (clickedDiv.classList.contains('filter-open')) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }
    }

}
