import {inject } from 'aurelia-framework';
import {DashboardService} from '../../shared/services/dashboardservice';
import {Router} from 'aurelia-router';
import {JwtService} from '../../shared/services/jwtservice';
import {HeaderService} from '../../shared/services/headerservice';
import {AttendanceService} from '../../shared/services/attendanceservice';
import {LoginService} from "../../shared/services/loginservice";


@inject(Router,DashboardService,JwtService,HeaderService,AttendanceService,LoginService)
export class RecordingTime {

    time='';
    comments='';
    attendanceDetails=[];
    inTime='';
    outTime='';
    record=[];
    message='';
    constructor(router,DashboardService,jwtService,headerservice,attendanceservice,loginService){
        this.update();
        this.jwtService = JwtService;
        this.headerservice = headerservice;
        this.attendanceService=attendanceservice;
        setInterval(() => this.update(), 1000);
        this.router = router;
        this.jwtService = jwtService;
        this.loginService = loginService;
    }

    attached(){
        this.isLoading = true;
        // if(this.jwtService.getToken() === undefined){
        //     this.loginService.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
        setTimeout(() => {
        this.isLoading = false;
        }, 1000);
        this.getAttendanceDetails();
    }

    /**
     * To update the current time on every minute
     */
    update(){
        this.currentDate=new Date();
    }

    /**
     * Function to get the attendance details of a employee
     */
    getAttendanceDetails(){
        this.empid = this.jwtService.getEmployeeId();
        this.attendanceService.GetAttendanceDetails(this.empid)
            .then(data => {
                this.attendanceDetails= data;
                this.inTime = this.attendanceDetails.length !==0 ? this.attendanceDetails[this.attendanceDetails.length - 1].InTime: null;
                this.outTime = this.attendanceDetails.length !==0 ? this.attendanceDetails[this.attendanceDetails.length - 1].OutTime : null;
                this.attendanceId=this.attendanceDetails.length !==0 ? this.attendanceDetails[this.attendanceDetails.length - 1].AttendanceID : null;
            });
    };

    /**
     * Function for Punch In and Punch Out.
     *
     * calling getAttendanceDetails function to reload the punch In/punch Out section.
     */
    saveTime(){
        event.preventDefault();
        if(this.inTime !== null && this.outTime === null){
            let inAndOut={AttendanceID:this.attendanceId,OutTime:this.record.Time, OutTimeComment:this.record.Comments,EmployeeID:this.record.EmployeeID};
            const sendAttendanceDate = this.attendanceService.SaveAttendance(inAndOut);
            sendAttendanceDate.then(
                async () => await this.getAttendanceDetails(),
                this.message="Successfully punched out",
                this.record.Comments=''
            ).catch(e => console.error(e))
        }else {
            let inAndOut={InTime:this.record.Time,InTimeComment:this.record.Comments,EmployeeID:this.record.EmployeeID};
            const sendAttendanceDate = this.attendanceService.SaveAttendance(inAndOut);
            sendAttendanceDate.then(
                async () => await this.getAttendanceDetails(),
                this.message="Successfully punched in",
                this.record.Comments=''
            ).catch(e => console.error(e))
        }
    }
}
