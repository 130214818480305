import {inject} from 'aurelia-dependency-injection';
import {EmployeeService} from '../../shared/services/employeeservice';
import {Router} from 'aurelia-router';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import Scrollbar from 'smooth-scrollbar';
import {JwtService} from '../../shared/services/jwtservice';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {LoginService} from "../../shared/services/loginservice";
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import {OrganizationComponent} from '../organization/organizationcomponent';
import {ApproveCompOff} from "../attendancetracking/compoff/approve/approve";


@inject(EmployeeService, Router, ValidationControllerFactory, JwtService, DialogController, DialogService, LoginService)
export class ResignationFormComponent {
    questionError = false;
    ApprovalsValidation = false;
    isLoading = false;
    questionError = false;
    prevPass;
    questionlist = [];
    Reasonemployee = '';
    RevokeWithdrawExitID = 0; // Assuming this is a property in your view-model
    Statustext = ''; // Assuming this is a property in your view-model

    selectedOptions = {}; // Object to hold selected radio options
    textAreaValues = {}; // Object to hold values of text areas


    constructor(employeeServices, router, controllerFactory, jwtService, loginservice) {
        this.employeeServices = employeeServices;
        this.router = router;
        this.controller = controllerFactory.createForCurrentScope();
        this.jwtService = jwtService;
        this.empid = this.jwtService.getEmployeeId();
        this.dialogService = DialogService;
        this.loginservice = loginservice;
        this.policy;
        ValidationRules
            .ensure('comment').displayName("Comments").required()
            .ensure('resdate').displayName("Resignation date").required()
            .ensure('DateOfBirth').displayName("Requested last working day").required()
            .ensure('Reasonemployee').displayName("Reason").required()
            .ensure('Reasonforwithdraw').displayName("Reason for withdraw").required()
            .on(this);
    }

    async attached() {
        this.employeeServices.GetApproversValidation(this.empid).then(data => {
            this.Apprvol = data;

        });

        this.isLoading = true;
        const exit = await this.employeeServices.GetEmployeeExitDetails(this.empid);
        this.isLoading = false;
        this.PolicyLWD = exit.PolicyLWD;
        this.currentDate = new Date();
        this.ResignationDate = this.formatDate(this.currentDate);
        this.Reason = exit.ReasonToEnableExit;
        this.status = exit.StatusCode;
        this.personId = exit.PersonID;
        this.enableexitID = exit.ExitID;
        this.exitTypeID = exit.ExitTypeID;
        this.PageFrom = "ResignationForm";
        this.StatusCode = exit.StatusCode;
        this.Statustext = exit.StatusCode;
        this.Reasonemployees = exit.ReasonforResignation;
        this.Reasonforwithdraw = exit.ReasonforWithdraw;
        this.EmployeeName = exit.EmployeeName;
        this.Designation = exit.DepartmentName;
        this.photopath = exit.PhotoPath;
        this.DeptPositionPositionLevelName = exit.DeptPositionPositionLevelName;
        this.dateofjoining = exit.DateOfJoining;
        this.Rmname = exit.ReportingMangerName;
        this.resdates = exit.ResignationDate;
        this.DateOfBirths = exit.RequestedLWDtxt;
        this.Employeecode = exit.EmployeeCode;
        this.StatusCodetxt = exit.StatusCodetxt;
        this.VisibleToHr = exit.HRComments;
        this.RevokeWithdrawExitID = exit.RevokeWithdrawExitID;
        if (this.StatusCode === 'ENB') {
            this.apply = 'Submit';
            this.Submit = 'ACT';
            this.StatusCode = 'ACT';
        } else if (this.StatusCode === 'PEA') {
            this.apply = 'Withdraw';
            this.Withdraw = 'WIT';
            this.StatusCode = 'PEA';
        }


        flatpickr(this.datePicker, {
            dateFormat: 'd-m-Y',
            minDate: new Date(),
            maxDate: null,
            defaultDate: null,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens: false,
            onClose: function (selectedDates, dateStr, instance) {
            }
        });
        this.calendarIcon.addEventListener('click', () => {
            this.datePicker._flatpickr.open();
        });

        flatpickr(this.FromdatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: new Date(Date.now() - 15 * 24 * 60 * 60 * 1000),
            maxDate: null,
            defaultDate: null,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens: false,
            onClose: (selectedDates, dateStr, instance) => {
                this.FromDate = dateStr;
                if (this.ToDate && new Date(this.ToDate) < new Date(dateStr)) {
                    this.ToDate = dateStr;
                    this.ToCalendar.setDate(dateStr);
                }

            }
        });
        this.FromcalendarIcon.addEventListener('click', () => {
            this.FromdatePicker._flatpickr.open();
        });

        this.isLoading = true;
        const exitQuestions = await this.employeeServices.GetExitQuestions(this.empid, this.enableexitID, this.exitTypeID, this.PageFrom);
        this.questionlist = exitQuestions.ListQuestion;
        this.questionData = exitQuestions;
        this.isLoading = false;
    }

    formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    handleFileSelect(files) {
        const file = files[0];
        this.file = file;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        this.DocumentName = file.name;
        reader.onload = () => {
            this.file = reader.result;
        };
    }

    openFileDialog() {
        document.getElementById('attachments').click();
    }

    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }

    cancel() {
        this.router.navigateToRoute('dashboard');
    }

    formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    handleDateSelection() {

        this.handleSearch();
    }

    async handleSearch() {
        this.policydte = this.resdate;
        this.employeeServices.GetNoticePeriodByPositionId(this.policydte, this.empid)
            .then(response => {
                this.isLoading = true;
                this.policydate = response;
                this.isLoading = false;
            });
    }


    save(questionlist) {
        event.preventDefault();
        const formData = [];

        this.controller.validate()
            .then(result => {
                if (result.valid) {
                 if (this.StatusCode === "PEA" || this.StatusCode === "APP") {

                        let data = {
                            RequestedLWD: this.DateOfBirth,
                            ExitID: this.enableexitID,
                            DocumentName: this.DocumentName,
                            Actions: this.StatusCode,
                            DocumentPath: this.file,
                            LogginEmployeeID: this.empid,
                            //Actions:this.Actions,
                            ReasonforResignation: this.Reasonemployee,
                            ReasonforWithdraw: this.Reasonforwithdraw,
                            HRComments: this.comment,
                            PolicyLWD: this.policydatetxt,
                            ResignationDate: this.resdate,
                        };
                        this.isLoading = true;
                        this.employeeServices.PostResignationWithdraw(data)
                            .then(() => {
                                this.isLoading = false;
                                this.message = "Resignation withdrawn submitted ";
                                this.attached();
                                setTimeout(() => {
                                    this.message = "";
                                }, 3000);
                            });
                        this.comment = '';
                        this.DocumentName = '';
                        this.file = '';
                        this.hrcomment = '';

                        this.Reasonemployee = '';
                        //  dashboard
                        const errorElement = document.querySelector(".scrolltoerrorsuccess");
                        if (errorElement) {
                            errorElement.scrollIntoView({behavior: "auto", block: "start", inline: "nearest"});
                            window.scrollBy(0, -200);
                        }
                    }
                    else
                    {
                    const mandatoryRadioQuestions = questionlist.filter(question => question.FieldTypeName === 'Radio Option' && question.IsManditory === true);
                    // Iterate through questionlist
                    for (const question of questionlist) {

                        // Check if the question is a Radio Option type
                        if (question.FieldTypeName === 'Radio Option') {
                            // Check if an option is selected
                            if (this.selectedOptions[question.Seqno]) {
                                const selectedOptionId = Number(this.selectedOptions[question.Seqno]);
                                const selectedOption = question.ListofOptions.find(option => option.PreExitQuestionOptionID === selectedOptionId);
                                formData.push({
                                    ExitQuestionID: question.PreExitQuestionID,
                                    // Response: this.selectedOptions[question.Seqno],
                                    Response: selectedOption.Option,
                                    FieldTypeName: question.FieldTypeName,
                                    Option: selectedOption.Option

                                });
                            }
                        } else if (question.FieldTypeName === 'TextBox Option') {
                            // Check if Statustext is not 'PEA' or 'APP'
                            if (this.Statustext !== 'PEA' && this.Statustext !== 'APP') {
                                formData.push({
                                    ExitQuestionID: question.PreExitQuestionID,
                                    Response: this.textAreaValues[question.Seqno],
                                    FieldTypeName: question.FieldTypeName,
                                    Option: question.Option
                                });
                            }
                        }
                    }
                    for (const question of mandatoryRadioQuestions) {
                        // Check if there is no selected option for this question
                        if (!this.selectedOptions[question.Seqno]) {
                            // Throw an error and prevent form submission
                            this.questionError = true;
                            const errorElement = document.querySelector(".scrolltoerrorsuccess");
                            if (errorElement) {
                                errorElement.scrollIntoView({behavior: "auto", block: "start", inline: "nearest"});
                                window.scrollBy(0, -200);
                            }
                            this.dynamicQuestionFormError = `Error: Mandatory question ${question.PreExitQuestionID} has no option selected.`;
                            return; // Stop further processing
                        }
                    }
                    const refreshEvent = new CustomEvent('refreshAddSkill');
                    if (this.StatusCode != "PEA" && this.StatusCode != "APP") {
                        let data = {
                            RequestedLWD: this.DateOfBirth,
                            ExitID: this.enableexitID,
                            DocumentName: this.DocumentName,
                            Actions: this.StatusCode,
                            DocumentPath: this.file,
                            LogginEmployeeID: this.empid,
                            HRComments: this.comment,
                            PolicyLWD: this.policydate.policydatetxt,
                            PersonID: this.empid,
                            ResignationDate: this.resdate,
                            ReasonforResignation: this.Reasonemployee,
                            HRComment: this.hrcomment,
                            ListResponses: formData
                        };

                        const mandatoryQuestionsArray = this.questionData.MandatoryQueText.split(",").map(Number);
                        const mandatoryobjectiveArray = this.questionData.MandatoryQueRadio.split(",").map(Number);
                        const radioOptionQuestions = this.questionlist.filter(question => question.FieldTypeName === "Radio Option" && question.IsManditory === true);

                        // Extract PreExitQuestionID from filtered items
                        const preExitQuestionIDs = radioOptionQuestions.map(question => question.PreExitQuestionID);

                        const emptyResponses = data.ListResponses.filter(question => mandatoryQuestionsArray.includes(question.ExitQuestionID) && (!question.Response || question.Response.trim() === ""));
                        const radioEmptyResponses = data.ListResponses.filter(question => mandatoryobjectiveArray.includes(question.PreExitQuestionID) && (!question.Response || question.Response.trim() === ""));
                        if (emptyResponses.length > 0) {

                            this.questionError = true;
                            const errorElement = document.querySelector(".scrolltoerrorsuccess");
                            if (errorElement) {
                                errorElement.scrollIntoView({behavior: "auto", block: "start", inline: "nearest"});
                                window.scrollBy(0, -200);
                            }
                            this.dynamicQuestionFormError = `Error: Mandatory question ${emptyResponses.map(q => q.ExitQuestionID).join(', ')} has null or empty response.`
                            // Handle error, display message or prevent form submission
                            return;
                        } else {
                            if (this.Apprvol === true) {
                                this.ApprovalsValidation = true;
                                const errorElement = document.querySelector(".scrolltoerrorsuccess");
                                if (errorElement) {
                                    errorElement.scrollIntoView({behavior: "auto", block: "start", inline: "nearest"});
                                    window.scrollBy(0, -200);
                                }
                            } else {
                                // No empty responses, proceed with form submission
                                this.isLoading = true;
                                this.questionError = false;
                                this.employeeServices.PostResignationRequest(data)
                                    .then(() => {
                                        this.isLoading = false;
                                        this.message = "Resignation form submitted successfully. ";
                                        this.attached();
                                        setTimeout(() => {
                                            this.message = "";
                                        }, 10000);
                                    });
                                this.comment = '';
                                this.DocumentName = '';
                                this.file = '';
                                this.hrcomment = '';
                                this.DateOfBirth = '';
                                this.resdate = '';
                                this.Reasonemployee = '';
                                this.selectedOptions = {};
                                this.textAreaValues = {};
                            }

                        }
                    }
                    
                }
                }
            });


    }

    findTextarea(seqno) {
        // Helper function to find the corresponding textarea based on seqno
        const textareas = document.querySelectorAll('textarea');
        for (const textarea of textareas) {
            if (textarea.previousElementSibling.textContent.includes(`${seqno}.`)) {
                return textarea;
            }
        }
        return null;
    }

    organization(user) {
        this.dialogService.open({viewModel: OrganizationComponent, model: user})
    }
}
