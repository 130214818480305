import {inject} from 'aurelia-framework';
import {JwtService} from '../../../shared/services/jwtservice';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {SalaryRevisionService} from '../../../shared/services/salaryrevisionservice';
import {QuestionnairePopup} from "../questionnairePopup/questionnairePopup";
import {OtherEmployeesInSamePosition} from "../otheremployeesinsameposition/otheremployeesinsameposition"
import {config} from "../../../shared/services/config";
import { AppreciationService } from '../../../shared/services/appreciationservice';

@inject(DialogController, JwtService, DialogService, SalaryRevisionService,AppreciationService)
export class PromotionLetter {
    ToDate = '14-12-2023';
    FromDate = '11-01-2023';
    pageSize1 = config.salaryGrid_PazeSize;
    pageSize = config.salaryGrid_PazeSize;
    pageSize2 = config.salaryGrid_PazeSize;
    MyAppreciationSummay=[];
    AppreciationDetails=[];
    configimgurl=config.img_url;
    previousYear = this.getPreviousYear();

    constructor(controller, JwtService, DialogService, SalaryRevisionService,appreciationService) {
        this.controller = controller;
        this.jwtService = JwtService;
        this.empid = this.jwtService.getEmployeeId();

        this.dialogService = DialogService;
        this.salaryRevisionService = SalaryRevisionService;
        this.appreciationService=appreciationService;
    }

    activate(salaryData) {
        this.params = salaryData;
        this.SalaryRevisionID = this.params.SalaryRevisionID;
        this.SelectedEmployeeID = this.params.selectedEmployeeID;
        this.EmployeeID=this.params.EmployeeID;
    }

    attached() {
        this.salaryRevisionService.getEmployeeDetails(this.SalaryRevisionID).then(data => {
            this.getdetails = data;
            console.log(data);
            this.ActionName=this.getdetails.ActionName;
            this.SalaryRevisionID=this.getdetails.SalaryRevisionID;
            this.PersonId = this.getdetails.PersonId;
            this.startdate = this.getdetails.RevisionPeriodFrom;
            this.enddate = this.getdetails.RevisionPeriodTo;
            this.Position = this.getdetails.Position;
            this.LevelName = this.getdetails.LevelName;
            this.DepartmentName=this.getdetails.DepartmentName;
            this.WorkexperinceText=this.getdetails.WorkexperinceText;
            this.EmployeeID=this.getdetails.EmployeeID;

            this.salaryRevisionService.GetTimeoffSummaryByPersonID(this.getdetails.PersonId, this.getdetails.RevisionPeriodFrom, this.getdetails.RevisionPeriodTo).then(data => {
                this.TimeoffSummary = data.List;

            });
            this.salaryRevisionService.AppreciationByEmployeeID(this.getdetails.EmployeeID,this.getdetails.RevisionPeriodFrom, this.getdetails.RevisionPeriodTo).then(data => {
                this.appreciation = data;
               // console.log('hii');
               // console.log(this.appreciation);
            });

            this.salaryRevisionService.GetCertificationsByPersonID(this.PersonId,this.getdetails.RevisionPeriodFrom, this.getdetails.RevisionPeriodTo).then(data => {
                this.certification = data;
                console.log('hii');
                console.log(this.certification);
            });

            this.appreciationService.getMyAppreciationSummary(this.EmployeeID,this.previousYear)
            .then(MyAppreciationSummay =>{
              this.MyAppreciationSummay = MyAppreciationSummay.EmployeeAppreciationModelList;  
              console.log(this.MyAppreciationSummay);
              });

              this.appreciationService.getMyAppreciationDetails(this.EmployeeID,this.previousYear)
              .then(AppreciationDetails =>{
                this.AppreciationDetails = AppreciationDetails;  
                this.selectedCategoryDetails=this.AppreciationDetails;
                });
        });

    }

    getPreviousYear() {
        const myDate = new Date();
        myDate.setFullYear(myDate.getFullYear() ); // -1
        return myDate.getFullYear();
      }

    selectedCategoryDetails = [];
    show(option)
    {
      if(option != '')
      {
        this.selectedCategoryDetails = this.AppreciationDetails.filter((getappreciation) => getappreciation.CategoryName === option.CategoryName);
      }
      else{
        this.selectedCategoryDetails=this.AppreciationDetails;
      }
      
    }

    performanceClick() {
        event.preventDefault();
        const parentDiv = event.target.parentNode;
        parentDiv.classList.toggle('performance-open');
    }

    QuestionnairePopup() {
        const data = {
            SalRevisionID: this.SalaryRevisionID,
            EmployeeID: this.empid
            //this.SelectedEmployeeID
        };
        this.dialogService.open({viewModel: QuestionnairePopup, model: data});

    }
    OtherEmployeesInSamePosition(){
        const otherEmployee={
            PersonId:this.PersonId,
                Position:this.Position,
            LevelName:this.LevelName,
            DepartmentName:this.DepartmentName,
        }
        this.dialogService.open({viewModel:OtherEmployeesInSamePosition, model:otherEmployee })
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }

    handleFileSelect(files) {
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024;
        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 4000);
            this.removeFile();
            return;
        }
        else if (file.size <= maxSize) {
            this.files = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentNames = file.name;
            reader.onload = () => {
                this.files = reader.result;
            };
        }
    }

    removeFile() {
        this.files = null;
        this.DocumentNames = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }

}
